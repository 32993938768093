import React, { useState, useContext,useEffect } from 'react'
import { CompanyContext } from '../../global_setting/Store';
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import history from '../../history'
import FooterFixed from './FooterFixed'

function Sightseeing() {
    const [companyDetails] = useContext(CompanyContext);

    const [websiteDetails,setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-extra-pages-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;
                    
                    setwebsiteDetails({ "template_identity":result.template_identity,"extra_seo":result.extra_seo_details && result.extra_seo_details.original,"hotel_event":result.hotel_offers && result.hotel_offers.original});

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])

    const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font
    const [scrolled,setscroll]=useState(false)
    if(scrolled===false){
        window.scrollTo(0,0)
        }
        
    window.addEventListener('scroll', function() {
        setscroll(true);
    });
    return(
        <div>
            {(websiteDetails === undefined) ? <div className="loader"></div> : <Header />}
            <section className="banner_area" style={{ backgroundImage: "url(" + require("../6/img/sightseeing/kasol1.jpg") + ")" }}>
            <div className="container">
                <div className="banner_inner_content">
                    <h3>Sightseeing</h3>
                    <ul>
                        <li className="active"><a onClick={() => history.push(config.PATH + 'home')} style={{ fontFamily: websitefont }}>Home</a></li>
                        <li><a style={{ fontFamily: websitefont, color: "white" }}>Sightseeing</a></li>
                    </ul>
                </div>
            </div>
        </section>
        <section className="activities_area">
        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/1.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Manikaran</h4>
                            <p>Famous for its hot water springs on the bank of the river Parvati. The water is so hot that Dal, Rice etc. can be cooked in it. A well known pilgrimage place on account of ancient Hindu Temple & Gurudwara. According to a legend Manikaran is also associated with lord Shiva and divine consort Parvati who lost and recovered her earring here.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/2.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Bijli Mahadev</h4>
                            <p>Bijli Mahadev is one of the sacred temples of the Indian state of Himachal Pradesh. It is located at an altitude of about 2,438 m in the Kullu Valley. Bijli Mahadev is one of the excellent temples in India. Located 10 km from Kullu across the Beas river, it can be approached by a rewarding trek of 3 km.<br/>
                               A panoramic view of Kullu and Paravati valleys can be seen from the temple. The 60 feet high staff of Bijli Mahadev temple glistens like a silver needle in the sun. In this temple of lightning it is said that the tall staff attracts the divine blessings in the form of lightning. It is believed that the priest of the temple has to restore the Shiva linga placed inside the temple using butter and sattoo after every lightning as it shatters to pieces with flash of lightning.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/3.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Parashar Lake</h4>
                            <p>Prashar Lake lies 49 km north of Mandi, Himachal Pradesh, India, with a three storied pagoda-like temple dedicated to the sage Prashar. The lake is located at a height of 2730 m above sea level. With deep blue waters, the lake is held sacred to the sage Prashar and he is regarded to have meditated there. Surrounded by snow-capped peaks and looking down on the fast flowing river Beas, the lake can be approached via Drang.<br/>
                               The temple was built in the thirteenth century and legend has it was built by a baby from a single tree. The lake has a floating island in it and it is said to be unclear how deep it is, with a diver not being able to determine its depth.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/4.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Malana Village</h4>
                            <p>Malana is an ancient Indian village in the state of Himachal Pradesh. This solitary village in the Malana Nala, a side valley of the Parvati Valley to the north-east of Kullu Valley, is isolated from the rest of the world. The majestic peaks of Chandrakhani and Deotibba shadow the village. It is situated on a remote plateau by the side of the torrential Malana river, at a height of 3,029 metres (9,938 ft) above sea level. Unaffected by modern civilisation, Malana has its own lifestyle and social structure. People are strict in following their customs.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/5.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Rohtang Pass</h4>
                            <p>The highest point on the road. It offers limited skiing but ample trekking opportunities at an altitude of 4112 mts. on the high way Keylong to Leh. The pass affords a wide spread Panorama of mountain scenery almost directly to the well defined sonepani glacier slightly left the twin peaks of the geypan.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/6.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Jagatsukh</h4>
                            <p>Fmous Gayatri and Shiva temple. Shiva temple in pyramid from with beautiful carvings dates back to 8th century. Distance from Manikaran is nearly 65 Kms.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/7.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Solang Valley</h4>
                            <p>Solang Valley popularly known as Snow Point, 95 Km from Manikaran and famous for its 300-meter ski lift. The Ski Lift ticket is Rs. 500 per head for a trip both ways. It is a picturesque spot and offers splendid views of glaciers and the snow-capped mountains. Solang valley also offers attractions like paragliding, snorkeling etc. Jagatsukh, the former capital of Manali, is also an important spot.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/8.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Hadimba Temple</h4>
                            <p>Located in lovely wilderness and natural setting in Manali. This four tiered pagoda shaped temple erected by Raja Bahadur Singh in 1553 is a marvelous piece of wooden carving and figures</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/9.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Roerich Art Gallery</h4>
                            <p>Nicholas Reorich Art Gallery is a popular attraction in Naggar. It is situated approximately 2 km from the major town. The museum exhibits a private collection of paintings of the Russian painter, Nikolai Reorich, who lived here and died in the year 1947. Gallery of ground floor is dedicated to the paintings of Kullu, Spiti and Lahaul, created by the painter. The upper floor maintains the artist's private rooms.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/10.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Kher Ganga</h4>
                            <p>Located in Parvati Valley of Kullu district, just 22kms away from Manikaran is a beautiful place called Kheer Ganga. Kheer Ganga is not only popular for its hot springs, but also for the holy River Ganges that surrounds the virgin forests of this place and gushes down mischievously through the verdant forests. The trek to Kheer Ganga is considered to be the most refreshing with invigorating views on your way up and post the climb you are rewarded with an experience that will make you forget all your pains and aches – a refreshing bath in the natural hot water spring.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/11.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Vashisth Temple</h4>
                            <p>As the name indicates itself the place associated with saga Vashisth is 4 km. Manali town. This temple was built by the king Jayamajya around 1000 years back in the memory of his father.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/12.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Kothi (1980 M)</h4>
                            <p>Last habitation in the valley barely. From here commands a spectacular view of the glaciers and deep gorges.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/13.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Tibetan Monastery & Van Vihar</h4>
                            <p>A small monastery in a peaceful clerring has image of Buddha & some exquisite tangkhas at Manali. Famous for carpet weaving & Tibetan handicrafts.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="activities_item_inner">
            <div className="container">
                <div className="row activities_item_row">
                    <div className="col-md-6">
                        <div className="activities_img">
                            <img src={require("../6/img/sightseeing/14.jpg")} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="activities_content">
                            <h4>Naggar</h4>
                            <p>Magnificent trail heritage resort perched in the sloppy hill takes back you to glorious past. At Naggar, tt remained the seat of power till 1460 and then sifted to Kullu. Naggar is the seat of assembly of gods where they meat to alleviate the sorrows of humanity.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
         {(websiteDetails === undefined) ? '' : <FooterFixed/>}
         {(websiteDetails === undefined) ? '' : <Footer />}
        </div>
    )
}
export default Sightseeing;