import config from '../config';
const getUserInfo=async (home_url,setCompanyId)=>{

    //Changes Done
    if (home_url.includes(config.wbDemoLink)) {
  
      if (home_url.includes('template')) {
        home_url = 'heritancehotels.com'
      }
      else {
        home_url = config.PATH;
  
      }
    }
   //Changes Done

  fetch(config.apiEndpoint+'/wb-frontview/get-frontview-user-website',{
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
    body: JSON.stringify({ "home_url": "hukamholidayhome.com"})    

    
      }).then((response) => response.json())
      .then((responseJson) => {
        // console.log(responseJson);
          setCompanyId({"company_id":responseJson.data.company_id,"template_id":responseJson.data.template_id,"subdomain_name":responseJson.data.subdomain_name})
      })
  .catch((error) => {
  });
}

export default getUserInfo;