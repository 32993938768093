import React, { useState, useContext,useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { CompanyContext } from '../../global_setting/Store';
import Header from './Header'
import Footer from './Footer'
import config from '../../../src/config.json'
import history from '../../history'
import FooterFixed from './FooterFixed'

function Restaurant() {
    const [companyDetails] = useContext(CompanyContext);

    const [websiteDetails,setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-extra-pages-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;
                    
                    setwebsiteDetails({ "template_identity":result.template_identity,"extra_seo":result.extra_seo_details && result.extra_seo_details.original,"hotel_event":result.hotel_offers && result.hotel_offers.original});

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])

    const websitefont = websiteDetails && websiteDetails.template_identity[0].theme_font
    const [scrolled,setscroll]=useState(false)
    if(scrolled===false){
        window.scrollTo(0,0)
        }
        
    window.addEventListener('scroll', function() {
        setscroll(true);
    });
    return(
        <div>
            {(websiteDetails === undefined) ? <div className="loader"></div> : <Header />}
        <section className="banner_area" style={{ backgroundImage: "url(" + require("../6/img/restaurant/banner.JPG") + ")" }}>
            <div className="container">
                <div className="banner_inner_content">
                    <h3>Restaurant</h3>
                    <ul>
                        <li className="active"><a onClick={() => history.push(config.PATH + 'home')} style={{ fontFamily: websitefont }}>Home</a></li>
                        <li><a style={{ fontFamily: websitefont, color: "white" }}>Restaurant</a></li>
                    </ul>
                </div>
            </div>
        </section>

        <section className="special_dish_area">
            <div className="container">
                <div className="special_dish_inner_area">
                    <div className="main_big_title">
                        <h2>our <span>Restaurant</span></h2>
                        <p>We offer an in-house restaurant in Kasol which serves multi-cuisine buffets. The restaurant can accommodate up to 40 people. We serve breakfast from 7.30 a.m. to 10.30 a.m. and dinner from 7.30 p.m. to 10.30 p.m.</p>
                        <p>Indulge in our delicious platters and have a hearty meal with your dear ones during your grand stay at one of the best Kasol hotels. </p>
                    </div>
                    <div className="row special_dish_inner">
                        <div className="col-md-4 col-sm-6">
                            <div className="special_dish_item">
                                <div className="dish_img">
                                    <img src={require("../6/img/restaurant/res1.JPG")} alt="restaurant" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="special_dish_item">
                                <div className="dish_img">
                                    <img src={require("../6/img/restaurant/res2.JPG")} alt="restaurant" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="special_dish_item">
                                <div className="dish_img">
                                    <img src={require("../6/img/restaurant/res4.JPG")} alt="restaurant" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {(websiteDetails === undefined) ? '' : <FooterFixed/>}
        {(websiteDetails === undefined) ? '' : <Footer />}
        </div>
    )
}
export default Restaurant;
