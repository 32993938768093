import React, { useState, useContext, useEffect } from 'react'
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import Header from './Header'
import Footer from './Footer'
import history from '../../history'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import FooterFixed from './FooterFixed'
import _debounce from 'lodash.debounce'
import MetaTags from 'react-meta-tags';
import moment from 'moment';
import { Link } from 'react-router-dom'
import CustomFooterSection from './sections/CustomFooterSection';


function RoomDetails() {

    const [companyDetails] = useContext(CompanyContext);
    let company_id_val = companyDetails && companyDetails.company_id;

    const [roomDetailsPageData, setRoomDetailsPageData] = useState();


    let hotel_room_url;
    hotel_room_url = window.location.pathname;
    hotel_room_url = hotel_room_url.replace(config.PATH, "");


    useEffect(() => {
        (async function () {
            try {
                if (company_id_val) {
                    let company_id = companyDetails.company_id
                    let template_id = companyDetails.template_id
                    let all_hotel_rooms = [];
                    let display_room_details;
                    let display_default_footer = true;

                    const pageData = await fetch(
                        config.apiEndpoint + '/wb-frontview/get-frontview-room-page-data/' + company_id + '/' + template_id
                    );
                    const result = await pageData.json();

                    if (result && result.room_details.data) {
                        for (let i = 0; i < result.room_details.data.length; i++) {
                            for (let j = 0; j < result.room_details.data[i].length; j++) {
                                all_hotel_rooms.push(result.room_details.data[i][j]);
                            }
                        }
                    }

                    if (all_hotel_rooms && result.room_services) {
                        for (let p = 0; p < all_hotel_rooms.length; p++) {
                            for (let q = 0; q < result.room_services.length; q++) {
                                if (all_hotel_rooms[p].hotel_id === result.room_services[q].hotel_id && all_hotel_rooms[p].room_type_id === result.room_services[q].room_type_id) {
                                    all_hotel_rooms[p].room_services = result.room_services[q].room_services;
                                }
                            }
                        }
                    }

                    if (all_hotel_rooms && result.private_washroom) {
                        for (let p = 0; p < all_hotel_rooms.length; p++) {
                            for (let q = 0; q < result.private_washroom.length; q++) {
                                if (all_hotel_rooms[p].hotel_id === result.private_washroom[q].hotel_id && all_hotel_rooms[p].room_type_id === result.private_washroom[q].room_type_id) {
                                    all_hotel_rooms[p].private_washroom = result.private_washroom[q].private_washroom;
                                }
                            }
                        }
                    }


                    for (let k = 0; k < all_hotel_rooms.length; k++) {
                        let final_type = all_hotel_rooms[k].room_type.replace(/[^a-zA-Z ]/g, "");
                        final_type = final_type.replace(/ +/g, "_");
                        final_type = final_type.toLowerCase();
                        if (final_type === hotel_room_url) {
                            display_room_details = all_hotel_rooms[k];
                        }
                    }


                    if (result && result.room_page_data && result.room_page_data.page_section) {
                        for (let i = 0; i < result.room_page_data.page_section.length; i++) {
                            if (result.room_page_data.page_section[i].section_names === "Custom Footer") {
                                display_default_footer = false
                            }
                        }
                    }


                    setRoomDetailsPageData({ "room_details_data": display_room_details, "display_default_footer": display_default_footer })

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [company_id_val])

    const [screen_size, setScreensize] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = _debounce(() => setScreensize(window.innerWidth), 100)
        window.addEventListener('resize', handleResize);
    });


    const [scrolled, setscroll] = useState(false)
    if (scrolled === false) {
        window.scrollTo(0, 0)
    }

    window.addEventListener('scroll', function () {
        setscroll(true);
    });

    let hotel_id = roomDetailsPageData && roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.hotel_id
    let video_banner_link = roomDetailsPageData && roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.video_banner_link
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("."), video_banner_link.length)
    video_banner_link = video_banner_link && video_banner_link.slice(video_banner_link.indexOf("/") + 1, video_banner_link.length)

    let display_video_banner_link = roomDetailsPageData && roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.video_banner

    let fromdate = moment()
    let todate = moment(fromdate).add(1, 'days')
    let to_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    var iframe
    let encryptData
    if (isNaN(from_date) && isNaN(to_date)) {
    }
    else {
        let allData = to_date + "|" + from_date + '|' + hotel_id + '|' + "" + '|' + "" + '|' + ""
        encryptData = btoa(allData);
        // iframe = encryptData
    }

    return (
        <div className="room-details">
            {(roomDetailsPageData === undefined) ? <div className="loader"></div> : <Header />}


            {roomDetailsPageData && roomDetailsPageData.room_details_data &&
                <MetaTags>
                    <meta charSet="UTF-8" />
                    <title>{roomDetailsPageData.room_details_data.seo_title}</title>
                    <meta name="description" content={roomDetailsPageData.room_details_data.seo_description} />
                </MetaTags>
            }




            {roomDetailsPageData && roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.banner &&
                <section className="banner_area" style={{ backgroundImage: "url(" + config.wbImagesEndpoint + '/' + roomDetailsPageData.room_details_data.banner + ")" }}>
                    <div className="container">
                        <div className="banner_inner_content">
                            <h3 >{roomDetailsPageData.room_details_data.banner_title}</h3>
                            <ul>
                                <li className="active"><a onClick={() => history.push(config.PATH + 'home')}>Home</a></li>
                                <li><a style={{ color: 'white' }}>{roomDetailsPageData.room_details_data.banner_title}</a></li>
                            </ul>
                        </div>
                    </div>
                </section>}


            {roomDetailsPageData && roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.video_banner && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-wrapper">
                        <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                            <source src={config.wbImagesEndpoint + '/' + roomDetailsPageData.room_details_data.video_banner} type="video/mp4" />
                            <source src={config.wbImagesEndpoint + '/' + roomDetailsPageData.room_details_data.video_banner} type="video/ogg" />
                        </video>
                    </div>
                </div>
            </section>}


            {!display_video_banner_link && video_banner_link && <section>
                <div className="video-date-picker-wrapper">
                    <div className="video-container-youtube">
                        <iframe type="text/html" className="video-youtube" src={"https://www.youtube.com/embed/" + video_banner_link + "?&amp;autoplay=1&amp;loop=1&amp;rel=0&amp;showinfo=0&amp;controls=0&amp;color=white&amp;iv_load_policy=3&amp;"} allow="autoplay" frameBorder="0" allowFullScreen></iframe>
                    </div>
                </div>
            </section>}


            {roomDetailsPageData && roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.page_title && <section className="spec_resort_area">
                <div className="container">
                    <div className="center_page_title">
                        <h2>{roomDetailsPageData.room_details_data.page_title}</h2>
                        <p dangerouslySetInnerHTML={{ __html: roomDetailsPageData.room_details_data.page_description }}></p>
                    </div>
                </div>
            </section>}





            {roomDetailsPageData && roomDetailsPageData.room_details_data && (screen_size > 767) ?

                <section className="room_details_area">
                    <div className="container">
                        <div className="row room_details_inner">
                            <div className="col-md-8">
                                <div className="room_details_content">
                                    <div className="room_d_main_text">
                                        <div className="room_details_img" >
                                            {roomDetailsPageData.room_details_data.room_image &&
                                                <OwlCarousel
                                                    className="owl-theme"
                                                    items={1}
                                                    autoplay={true}
                                                    autoplaySpeed={950}
                                                    loop
                                                    nav

                                                >
                                                    {roomDetailsPageData.room_details_data.room_image.map(function (items, i) {
                                                        return (
                                                            <div className="item" key={i}>
                                                                <div className="roomdetails">
                                                                    <img src={config.imageEndpoint + '/' + items} alt="" />
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                </OwlCarousel>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="book_now_button room-det">
                                    <button className="book_now_btn_black">
                                        {/* <Link
                                        to={{
                                            pathname: config.PATH + 'booknow',
                                            search: '?' + (iframe),
                                        }}
                                    >Book Now</Link> */}
                                    <a href={"https://" + companyDetails.subdomain_name +"/property?q="+encryptData}>Book Now</a>

                                    </button>
                                </div>
                                <br />
                                {roomDetailsPageData.room_details_data.room_image && roomDetailsPageData.room_details_data.room_image.map(function (items, i) {

                                    return (

                                        <img src={config.imageEndpoint + '/' + items} alt="" className="img-slider" key={i} style={{ height: "110px" }} key={i} />
                                    )

                                })}
                            </div>


                            <div className="col-md-4" >
                                <div className="search_right_sidebar">
                                    <div>

                                        <div className="row">
                                            <p style={{ fontWeight: "bolder" }}>Room Description</p>
                                            <br />
                                            <p dangerouslySetInnerHTML={{ __html: roomDetailsPageData.room_details_data.description }}></p>
                                        </div>
                                        <br />

                                        {roomDetailsPageData.room_details_data.room_services && roomDetailsPageData.room_details_data.room_services.length > 0 &&
                                            <div className="row">
                                                <p style={{ fontWeight: "bolder", padding: "0px 20px" }}>Room Facilities</p>
                                                <ul className="col-md-6">
                                                    {roomDetailsPageData.room_details_data.room_services.map(function (items, i) {
                                                        if (i % 2 == 0) {
                                                            return (
                                                                <li style={{ padding: "10px" }} key={i}><i className={items.icon} aria-hidden="true"></i> {items.service_title}</li>
                                                            )
                                                        }
                                                    })}
                                                </ul>
                                                <ul className="col-md-6">
                                                    {roomDetailsPageData.room_details_data.room_services.map(function (items, i) {
                                                        if (i % 2 != 0) {
                                                            return (
                                                                <li style={{ padding: "10px" }} key={i}><i className={items.icon} aria-hidden="true"></i> {items.service_title}</li>
                                                            )
                                                        }
                                                    })}
                                                </ul>
                                            </div>}
                                        <br />
                                        {roomDetailsPageData.room_details_data && roomDetailsPageData.room_details_data.private_washroom && roomDetailsPageData.room_details_data.private_washroom > 0 &&
                                            <div className="row">
                                                <p style={{ fontWeight: "bolder", padding: "0px 20px" }}>In your private Bathroom</p>
                                                <ul className="col-md-6">
                                                    {roomDetailsPageData.room_details_data.private_washroom.map(function (items, i) {
                                                        if (i % 2 === 0) {
                                                            return (
                                                                <li style={{ padding: "10px" }} key={i}><i className="fa fa-caret-right" aria-hidden="true"></i> {items.service_title}</li>
                                                            )
                                                        }
                                                    })}
                                                </ul>
                                                <ul className="col-md-6">
                                                    {roomDetailsPageData.room_details_data.private_washroom.map(function (items, i) {
                                                        if (i % 2 !== 0) {
                                                            return (
                                                                <li style={{ padding: "10px" }} key={i}><i className="fa fa-caret-right" aria-hidden="true"></i> {items.service_title}</li>
                                                            )
                                                        }
                                                    })}
                                                </ul>
                                            </div>}

                                        {roomDetailsPageData.room_details_data.room_view_type && <div className="row">
                                            <p style={{ fontWeight: "bolder", padding: "0px 20px" }}>View :</p>
                                            <ul className="col-md-6">
                                                <li style={{ padding: "10px" }}><i className="fa fa-caret-right" aria-hidden="true"></i> {roomDetailsPageData.room_details_data.room_view_type}</li>
                                            </ul>
                                        </div>}


                                        <br />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section> : roomDetailsPageData && <RoomDetailsMobile data={roomDetailsPageData.room_details_data} companyDetails={companyDetails}/>}
            {roomDetailsPageData && roomDetailsPageData.display_default_footer === false && <CustomFooterSection />}
            {roomDetailsPageData && roomDetailsPageData.display_default_footer === true && <Footer />}
            {roomDetailsPageData && <FooterFixed />}

        </div>
    );
}
function RoomDetailsMobile(props) {

    var room_details_data_mobile = props.data
    let hotel_id = room_details_data_mobile && room_details_data_mobile.hotel_id
    let companyDetails = props.companyDetails;

    let fromdate = moment()
    let todate = moment(fromdate).add(1, 'days')
    let to_date = new Date(moment(fromdate).format('MM/DD/YYYY')).getTime();
    let from_date = new Date(moment(todate).format('MM/DD/YYYY')).getTime();
    var iframe
    let encryptData

    if (isNaN(from_date) && isNaN(to_date)) {
    }
    else {
        let allData = to_date + "|" + from_date + '|' + hotel_id + '|' + "" + '|' + "" + '|' + ""
        encryptData = btoa(allData);
        // iframe = encryptData
    }


    return (
        <div className="room-details">
            <section className="room_details_area">
                <div className="container">
                    <div className="row room_details_inner">
                        <div className="col-md-8">
                            <div className="room_details_content">
                                <div className="room_d_main_text" style={{ paddingBottom: 0 }}>
                                    <div className="room_details_img" >
                                        <OwlCarousel
                                            className="owl-theme"
                                            items={1}
                                            autoplay={true}
                                            autoplaySpeed={950}
                                            loop
                                            nav
                                        >
                                            {room_details_data_mobile &&
                                                room_details_data_mobile.room_image.map(function (item, i) {
                                                    return (
                                                        <div className="item" key={i}>
                                                            <div className="roomdetails">
                                                                <img src={config.imageEndpoint + '/' + item} alt="" style={{ width: "100%", height: "419px" }} />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                        </OwlCarousel>
                                    </div>
                                    {room_details_data_mobile &&
                                        <div className="container-fluid" style={{ padding: "0px" }}>
                                            <div className="amenitiess_room" style={{ background: "#fff" }}>
                                                <div className="scrolling-wrapper rdetails">
                                                    {room_details_data_mobile.bed_type &&
                                                        <div className="card" style={{ marginRight: "30px" }}>
                                                            <div className="text-center"><i className="fa fa-bed" ></i></div>
                                                            <h6>{room_details_data_mobile.bed_type}</h6>
                                                        </div>}
                                                    {room_details_data_mobile.room_view_type &&
                                                        <div className="card" style={{ marginRight: "30px" }}>
                                                            <div className="text-center"><i className="fa fa-search"></i></div>
                                                            <h6>{room_details_data_mobile.room_view_type}</h6>
                                                        </div>}
                                                    {room_details_data_mobile.room_size_value && room_details_data_mobile.room_size_unit &&
                                                        <div className="card" style={{ marginRight: "30px" }}>
                                                            <div className="text-center"><i className="fa fa-window-maximize" ></i></div>
                                                            <h6>{room_details_data_mobile.room_size_value} {room_details_data_mobile.room_size_unit}</h6>
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>

                        </div>
                        <hr></hr>
                        {room_details_data_mobile &&
                            <div className="col-md-4" >
                                <div className="search_right_sidebar">
                                    <div>
                                        <p dangerouslySetInnerHTML={{ __html: room_details_data_mobile.description }} ></p>
                                        <br />
                                        {room_details_data_mobile.room_services && room_details_data_mobile.room_services.length > 0 &&
                                            <div className="row">
                                                <p style={{ fontWeight: "bolder", padding: "0px 20px" }}>Room Facilities</p>
                                                <ul className="col-md-6">
                                                    {room_details_data_mobile.room_services.map(function (items, i) {
                                                        if (i % 2 === 0) {
                                                            return (
                                                                <li style={{ padding: "10px" }} key={i}><i className={items.icon} aria-hidden="true"></i> {items.service_title}</li>
                                                            )
                                                        }
                                                    })}
                                                </ul>
                                                <ul className="col-md-6">
                                                    {room_details_data_mobile.room_services.map(function (items, i) {
                                                        if (i % 2 !== 0) {
                                                            return (
                                                                <li style={{ padding: "10px" }} key={i}><i className={items.icon} aria-hidden="true"></i> {items.service_title}</li>
                                                            )
                                                        }
                                                    })}
                                                </ul>
                                            </div>}
                                        <br />
                                        {room_details_data_mobile.room_view_type &&
                                            <div className="row">
                                                <p style={{ fontWeight: "bolder", padding: "0px 20px" }}>View :</p>
                                                <ul className="col-md-6">
                                                    <li style={{ padding: "10px" }}><i className="fa fa-caret-right" aria-hidden="true"></i> {room_details_data_mobile.room_view_type}</li>
                                                </ul>
                                            </div>}
                                        <br />


                                        {room_details_data_mobile && room_details_data_mobile.private_washroom && room_details_data_mobile.private_washroom.length > 0 &&
                                            <div className="row">
                                                <p style={{ fontWeight: "bolder", padding: "0px 20px" }}>In your private Bathroom</p>
                                                <ul className="col-md-6">
                                                    {room_details_data_mobile.private_washroom.map(function (items, i) {
                                                        if (i % 2 == 0) {
                                                            return (
                                                                <li style={{ padding: "10px" }} key={i}><i className="fa fa-caret-right" aria-hidden="true"></i> {items.service_title}</li>
                                                            )
                                                        }
                                                    })}
                                                </ul>
                                                <ul className="col-md-6">
                                                    {room_details_data_mobile.private_washroom.map(function (items, i) {
                                                        if (i % 2 != 0) {
                                                            return (
                                                                <li style={{ padding: "10px" }} key={i}><i className="fa fa-caret-right" aria-hidden="true"></i> {items.service_title}</li>
                                                            )
                                                        }
                                                    })}
                                                </ul>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    <div className="book_now_button room-det">
                        <button className="book_now_btn_black">
                            {/* <Link
                            to={{
                                pathname: config.PATH + 'booknow',
                                search: '?' + (iframe),
                            }}
                        >Book Now</Link> */}
                        <a href={"https://" + companyDetails.subdomain_name +"/property?q="+encryptData}>Book Now</a>

                        </button>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default RoomDetails;