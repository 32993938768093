import React, { useState, useContext, useEffect } from 'react'
import { CompanyContext } from '../../global_setting/Store';
import config from '../../../src/config.json'
import 'react-dates/initialize';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import VideoSlider from './VideoSlider'
function Slider() {
    const [companyDetails] = useContext(CompanyContext);

    const [websiteDetails, setwebsiteDetails] = useState();
    var x = companyDetails && companyDetails.company_id;
    useEffect(() => {
        (async function () {
            try {
                if (companyDetails) {
                    var company_id = companyDetails.company_id
                    var template_id = companyDetails.template_id
                    const staticPageData = await fetch(
                        config.apiEndpoint + '/userwb/get-slider-data/' + company_id + '/' + template_id
                    );
                    const json = await staticPageData.json();
                    const result = json.data;
                    
                    setwebsiteDetails({ "slider_details": result.slider_details,"hotel_menus":result.hotel_menu_details,"slider_default": result.slider_default,"template_identity":result.template_identity,"hotel_menus":result && result.hotel_menu_details});

                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [x])

    const websitefont = websiteDetails && websiteDetails.template_identity && websiteDetails.template_identity[0].theme_font

    return (
        <div>
            {(websiteDetails && websiteDetails.slider_default && websiteDetails.slider_default.default_value ==="video")?
            <div className="c-slider">
                {(websiteDetails === undefined) ? '' : <VideoSlider />}
            </div>
            :
        <div className="c-slider">
            <div className="container-fluid" style={{ padding: "0px", margin: "0px" }} >
                <Carousel
                    infiniteLoop 
                    autoPlay={true}
                    interval={3000}
                    animateOut='fadeOut'
                    
                >
                    {websiteDetails && websiteDetails.slider_details && websiteDetails.slider_details.map(function (item, i) {
                        return (
                            <div key={i}>
                                <img src={config.wbImagesEndpoint + '/' + item.slider_image} alt={item.slider_image_alt}/>
                                <div className="slider-text">
                                    <h3 className="slider-caption slider-caption-1" style={{ fontSize: "45px", textAlign: "center",fontFamily:websitefont }}>{item.slider_caption}</h3>
                                </div>
                            </div>
                        )
                    })}
                </Carousel>

            </div>

        </div>
         }
    </div>
    )
}


export default Slider;

