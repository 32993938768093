import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'font-awesome/css/font-awesome.min.css';
import config from '../src/config.json'

// Changes Done
let home_url1 = window.location.origin;
let home_url

if (home_url1 === config.wbDemoLink) {
  home_url = window.location.href;
} else {
  home_url = home_url1.search('https') > -1 ? home_url1.replace('https://', '') : home_url1.replace('http://', '');
}



if (home_url.includes(config.wbDemoLink)) {
  if (home_url.includes('template')) {
    home_url = 'heritancehotels.com'
  }
  else {
    home_url = config.PATH;
  }
}
// Changes Done

fetch(config.apiEndpoint +'/wb-frontview/get-frontview-user-website',{
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({ "home_url": "hukamholidayhome.com"})

}).then((response) => response.json())
  .then((responseJson) => {
    ReactDOM.render(<App data={responseJson.data} />, document.getElementById('root1'));
  })
  .catch((error) => {
  });